import React from "react";
import PropTypes from "prop-types";

export const UnderlineHeader = ({ text, className }) => (
  <div className={`bebas-underline-header-container ${className || ""}`}>
    <h2 className="bebas-underline-header">{text}</h2>
    <div className="bebas-underline-header-underline" />
  </div>
);

UnderlineHeader.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default UnderlineHeader;
