import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import Content, { HTMLContent } from "../../components/Content";
import UNSLogo from "../../img/images/Unschool Logo Horizontal White (1).png";
import FAQSection from "../../components/FAQSection";
import NewsletterCard from "../../components/NewsletterCard";
import BinocularIcon from "../../img/images/icons/noun-binoculars-1236068-1.png";
import MissionIcon from "../../img/images/icons/noun-mission-3235128-1.png";
import WhoAreWeImage from "../../img/images/group-2210.png";
import WhatWeDoImage from "../../img/images/group-2211.png";
import UnderlineHeader from "../../components/UnderlineHeader";
import TestimonialSection from "../../components/TestimonialSection";
import StatsSection from "../../components/StatsSection";
import Img from "gatsby-image";
import { useLocation } from "@reach/router";
import { awardImageUrl, faqs } from "../../utils/constants";
import "../../components/sections/about.scss";

const Marquee =
  typeof window !== `undefined`
    ? require("react-simple-marquee").default
    : null;

export const AboutPageTemplate = (
  {
    // testimonials,
    // contentComponent,
    // faqs,
    // awards,
  }
) => {
  // const PageContent = contentComponent || Content;
  const location = useLocation();
  return (
    <section id={"about-us-page-container"}>
      <h2 id={"about-page-header"}>
        <span id={"about-page-header-gradient"}>Unschool:</span> an education
        ecosystem designed to bring out the best in you.
      </h2>

      <h4 id={"about-page-subheader"}>
        In a world where learning only comes from our formative years in school
        and college, Unschool is the new, easy, and definitive path for keeping
        up with the 21st century. It is the platform where you can finally stop
        trying to fit in a system crafted for the masses and learn in a unique
        way that brings out the best version of you.
      </h4>

      <StatsSection bgInherit />

      <div id="about-page-definition">
        <div id={"about-page-definition-logo"}>
          <img src={UNSLogo} alt="Unschool.in" />
        </div>
        <div id="about-page-definition-text">
          <h4>/ʌnˈskuːl/</h4>
          <p>
            Verb / The inspired act of venturing beyond conventional ways of
            learning. An education system crafted for each individual’s specific
            needs.
          </p>
        </div>
      </div>

      <div id={"about-page-vision-mission-container"}>
        <div className={"about-page-vision-mission-card"}>
          <div className={"vision-mission-card-image"}>
            <img src={BinocularIcon} alt={"Vision Image"} />
          </div>
          <div className={"vision-mission-card-text"}>
            <h4>Vision</h4>
            <h6>
              To provide alternative learning & teaching methods to enable the
              youth towards employability.
            </h6>
          </div>
        </div>

        <div className={"about-page-vision-mission-card"}>
          <div className={"vision-mission-card-image"}>
            <img src={MissionIcon} alt={"Mission Image"} />
          </div>
          <div className={"vision-mission-card-text"}>
            <h4>Mission</h4>
            <h6>
              To create a powerful online learning ecosystem for everyone,
              tailored for each one.
            </h6>
          </div>
        </div>
      </div>

      <div id={"about-page-who-are-we"}>
        <div className={"row"}>
          <div className={"col-12 col-md-6"}>
            <h4>Who are we?</h4>
            <p>
              We’re witnessing a generation that asks questions, has opinions
              and takes initiative to make the world a better place.
              <br />
              <span className={"who-we-are-bold"}>
                As Unschoolers, we’re{" "}
                <span className={"marigold-text"}>
                  catalysts of education for this generation.
                </span>
              </span>
            </p>
          </div>
          <div className={"col-12 col-md-6"}>
            <div className={"who-what-image-container"}>
              <img src={WhoAreWeImage} alt={"Who are we"} />
            </div>
          </div>
        </div>
      </div>

      <div id={"about-page-what-we-do"}>
        <div className={"row"}>
          <div className={"col-12 col-md-6"}>
            <div className={"who-what-image-container"}>
              <img src={WhatWeDoImage} alt={"Who are we"} />
            </div>
          </div>
          <div className={"col-12 col-md-6 about-page-what-we-do-text"}>
            <h4>What do we do?</h4>
            <p>
              <p>
                We enable people. To become the best version of themselves, to
                up-skill and, to teach. We work tirelessly towards making the
                youth more employable and skilled. <br />
                And we have the time of our lives doing it.
              </p>
              <p>
                <a href={location.origin} target="_blank">
                  Our Courses
                </a>{" "}
                are designed to prepare you for the future and give you the
                practical, raw knowledge that only an industry expert can
                deliver.
              </p>
              <p>
                <a href={`${location.origin}/work-with-us/`} target="_blank">
                  Careers
                </a>{" "}
                at Unschool is a place for the passionate, the misfits, and the
                crazy. If you believe in making a change, we believe in you.
              </p>
              <p>
                <a href={`${location.origin}/become-a-coach/`} target="_blank">
                  Our Coaches
                </a>{" "}
                are the essence of bringing about the revolution needed to make
                learning fun, meaningful and outcome-based.
              </p>
            </p>
          </div>
        </div>
      </div>

      <div
        id={"about-page-awards-container"}
        className="row logos-row-container"
      >
        <UnderlineHeader text={"Recognised & awarded by"} />
        <div className="logos-row-logos-container  award-row-logo-wrapper">
          {typeof window !== "undefined" && (
            <Marquee>
              {awardImageUrl.map((award,index) => (
                  <a className="logos-row-logo">
                    <img
                      key={index}
                      src={award.logoUrl}
                      className="award-logo"
                      alt={award.name}
                    />
                  </a>
                ))}
            </Marquee>
          )}
        </div>
      </div>

      <TestimonialSection />

      <FAQSection questions={faqs} />

      <NewsletterCard />
    </section>
  );
};

AboutPageTemplate.propTypes = {
  // testimonials: PropTypes.array,
  // contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const pageTitle = "About us | Unschool";
  const pageDescription =
    "In a world of conventional learning, Unschool is the new, definitive path for the 21st century to learn in a unique way that brings out the best version of you.";
  return (
    <Layout title={pageTitle} description={pageDescription}>
      <AboutPageTemplate
      // contentComponent={HTMLContent}
      // testimonials={data.testimonials.frontmatter.testimonials}
      // faqs={data.Faqs.frontmatter.questions}
      // awards={data.awards}
      // awardsMobileImages={data.awardsMobileImages}
      // title={post.frontmatter.title}
      // content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  // data: PropTypes.object.isRequired,
};

export default AboutPage;
